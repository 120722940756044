import React from 'react'
import styled from 'styled-components'
import Donation from '../../components/Donation'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'

function donation() {
  return (
    <Layout border='2px solid #424242' lang='en-us'>
        <Seo title='Shukran | Donation' descriptionEn='Donate to Shukran'  descriptionFr='Faîtes un don à Shukran'/>
        <Donation fr={false}/>
    </Layout>
  )
}

export default donation